/* eslint-disable max-len */
export default {
  mapboxApiKey: 'pk.eyJ1IjoibWFya2V0ZXItdGVjaG5vbG9naWVzIiwiYSI6ImNqendoMjVoejBocmUzb3E5aDBxaXNiNGQifQ.BrDmzfF2spPEsIiOAz-UkA',
  tinyMceEditorApiKey: 'who9qngavs7k32ny8hp2176lj44crb99w4pd1r4wdqzemwoo',
  leadsEndpoint: 'https://marketer-staging.herokuapp.com/leads',
  tagsEndpoint: 'https://marketer-staging.herokuapp.com/floodlight_tags.json',
  tagsPack: 'https://marketer-staging.herokuapp.com/packs/tags.js',
  portalHost: 'https://marketer-portal-staging.herokuapp.com',
  acceptedMessageOrigins: [],
  sentryDsn: 'https://06f5b34494b84ca3a1962b7cebd27cb9@sentry.production.meglermatch.no/21'
}
