import fetch from 'cross-fetch'
import queryString from 'query-string'
import { once } from 'lodash'
import { v4 as uuid } from 'uuid'

import credentials from 'config/credentials'

(function() {
  const phoneValues = [
    'phone', 'number', 'telefon', 'nummer', 'phoneNumber',
    'your-tel', 'tel', 'your_tel', 'your-phone', 'your_phone',
    'fields[telefon]', 'message[Telefon]', 'MMERGE4', 'phone-1',
    'mobilePhone', 'mobile', 'mobil', 'mobilnummer', 'cellPhone'
  ]
  const emailValues = [
    'email', 'mail', 'your-email', 'your_email', 'post', 'fields[ePost]',
    'fromEmail', 'email-1'
  ]
  const fullNameValues = [
    'fullName', 'fullname', 'full_name', 'full-name', 'customer[full_name]',
    'your-name', 'your_name',
    'fields[navn]', 'fromName', 'MMERGE3', 'name-1'
  ]
  const firstNameValues = ['firstName', 'firstname', 'first_name', 'first-name', 'fname']
  const lastNameValues = ['lastName', 'lastname', 'last_name', 'last-name', 'lname']
  const genericNameValues = ['name', 'navn']
  const sensitiveValues = [
    'password', 'Password', 'hidden_password',
    'hiddenPassword', 'passord', 'skjult_passord', 'skjultPassord', 'apikey'
  ]

  const nestedObjectKeys = ['contact']
  const avoidFields = ['subscribe', '_mc4wp_form_element_id', 'oppdragsnummer']
  const emailInputTypes = ['email', 'text']

  const EMAIL_REGEXP = /\S+@\S+\.\S+/
  const PHONE_REGEXP = /^[+]?(?:[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*){5,}$/
  // for searching names need to use all character + norwegian + spaces + -
  // for names like Øivind
  const NAME_REGEXP = /^[a-zA-ZÄäÀàÁáÂâÃãÅåǍǎĄąĂăÆæĀāÇçĆćĈĉČčĎđĐďðÈèÉéÊêËëĚěĘęĖėĒēĜĝĢģĞğĤĥÌìÍíÎîÏïıĪīĮįĴĵĶķĹĺĻļŁłĽľÑñŃńŇňŅņÖöÒòÓóÔôÕõŐőØøŒœŔŕŘřẞßŚśŜŝŞşŠšȘșŤťŢţÞþȚțÜüÙùÚúÛûŰűŨũŲųŮůŪūŴŵÝýŸÿŶŷŹźŽžŻż]+[\.\+\w\s-']*$/ // eslint-disable-line

  // param that shows submitting form event
  let formIsSubmited = false

  const allParamsArray = fetchAllParamsFromUrl()
  const tagsFromUrl = fetchUtmTagsFromUrl()
  const storedTags = fetchUtmTagsFromStorage()
  const tagsFromUrlEmpty = tagsEmpty(tagsFromUrl)
  const storedTagsEmpty = tagsEmpty(storedTags)

  storeUtmTags()

  const invokeSubmitLead = once(submitLead)

  async function submitLead(data) {
    const result = await fetch(credentials.leadsEndpoint, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    })
    formIsSubmited = false
    return result
  }

  function urlWithUtmTags() {
    // eslint-disable-next-line max-len
    if ((tagsFromUrlEmpty && storedTagsEmpty) || !tagsFromUrlEmpty) return new URL(window.location.href)

    return new URL(setUrlString(window.location.href, storedTags))
  }

  function setUrlString(url, tags) {
    return url + '?' + new URLSearchParams(tags).toString()
  }

  function storeUtmTags() {
    if(!tagsFromUrlEmpty) {
      sessionStorage.setItem('search_params', JSON.stringify(tagsFromUrl))
    }
  }

  function tagsEmpty(tags) {
    if (!tags) return true

    return Object.keys(tags).length === 0 && Object.getPrototypeOf(tags) === Object.prototype
  }

  function fetchUtmTagsFromUrl() {
    // If some params are missing due to the original bug reported in ->
    // https://marketer.atlassian.net/browse/MT-373, that means we need to include
    // other params than UTM tags (turning the filter off).
    const utmTagsArray = allParamsArray.filter(arr => arr[0].includes('utm_'))
    return Object.fromEntries(utmTagsArray)
  }

  function fetchAllParamsFromUrl() {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams.entries()
    return Array.from(searchParams)
  }

  function fetchUtmTagsFromStorage() {
    return JSON.parse(sessionStorage.getItem('search_params'))
  }

  function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  function findValueFromList(data, value) {
    const allFieldValues = [value, capitalize(value), value.toUpperCase()]
    return allFieldValues.reduce(function (result, element) {
      if (result) {
        return result
      }

      if (data[encodeURI(element)]) return data[encodeURI(element)]
    }, null)
  }

  function isStringValue(value) {
    if (!value) {
      return false
    }

    return Object.prototype.toString.call(value) === '[object String]'
  }

  function isObject(value) {
    if (!value) {
      return false
    }

    return Object.prototype.toString.call(value) === '[object Object]'
  }

  function findValueInJson(data, matcher) {
    return Object.keys(data).reduce(function (result, key) {
      if (result) {
        return result
      }

      if (!isStringValue(data[key]) && isObject(data[key]) && nestedObjectKeys.includes(key)) {
        const result = findValueInJson(data[key], matcher)

        if (result) {
          return result
        }
      }

      if (isStringValue(data[key]) && !avoidFields.includes(key) && data[key].match(matcher)) {
        return data[key]
      }
    }, null)
  }

  function findFieldValue(field, searchForValue) {
    const value = searchForValue(field)
    return value && value.value ? value.value : null
  }

  function findFirstFieldValue(values, searchForValue) {
    return values.reduce((value, field) => {
      return value || findFieldValue(field, searchForValue)
    }, null)
  }

  function findNameFromParts(searchForValue) {
    const firstName = findFirstFieldValue(firstNameValues, searchForValue)
    if (firstName) {
      const lastName = findFirstFieldValue(lastNameValues, searchForValue)
      return lastName ? `${firstName} ${lastName}` : firstName
    }

    return null
  }

  const fieldsFinders = {
    email(data) {
      let emailInput = null
      let emailInputValue = null

      emailValues.some((values) => {
        emailInput = findValueFromList(data, values)
        if (emailInput) {
          emailInputValue = emailInput
          return true
        }
      })

      if (emailInputValue !== null) return emailInputValue
      return findValueInJson(data, EMAIL_REGEXP)
    },
    phoneNumber(data) {
      let phoneInput = null
      let phoneInputValue = null

      phoneValues.some((values) => {
        phoneInput = findValueFromList(data, values)
        if (phoneInput) {
          phoneInputValue = phoneInput
          return true
        }
      })

      if (phoneInputValue !== null) return phoneInputValue
      return findValueInJson(data, PHONE_REGEXP)
    },
    fullName(data) {
      let nameInput = null
      let nameInputValue = null

      fullNameValues.some((values) => {
        nameInput = findValueFromList(data, values)
        if (nameInput) {
          nameInputValue = nameInput
          return true
        }
      })

      if (nameInputValue !== null) return nameInputValue

      const nameFromParts = findNameFromParts((value) => { return findValueFromList(data, value) })
      if (nameFromParts !== null) return nameFromParts

      genericNameValues.some((values) => {
        nameInput = findValueFromList(data, values)
        if (nameInput) {
          nameInputValue = nameInput
          return true
        }
      })
      if (nameInputValue !== null) return nameInputValue

      return findValueInJson(data, NAME_REGEXP)
    }
  }

  function findLeadContacts(data) {
    return Object.keys(fieldsFinders).reduce(function (fieldsValues, finderKey) {
      const value = fieldsFinders[finderKey](data)

      if (value) {
        fieldsValues[finderKey] = value
      }

      return fieldsValues
    }, {})
  }

  function paramIsPresent(value) {
    if (value && value.length > 0) return true
    return false
  }

  function isJson(item) {
    item = typeof item !== 'string' ? JSON.stringify(item) : item

    try {
      item = JSON.parse(item)
    } catch (e) {
      return false
    }

    if (typeof item === 'object' && item !== null) {
      return true
    }

    return false
  }

  function clearSensitiveJsonData(data) {
    return Object.keys(data).reduce(function (fieldsValues, key) {
      const value = data[key]
      if (!sensitiveValues.includes(key)) {
        fieldsValues[key] = value
      }
      return fieldsValues
    }, {})
  }

  function payloadHasUserParams(leadPayload) {
    if (!paramIsPresent(leadPayload.fullName)) return false
    return paramIsPresent(leadPayload.email) || paramIsPresent(leadPayload.phoneNumber)
  }

  function preparePayload(leadData) {
    const anonymized = anonymize(leadData)
    const queryParams = queryString.parse(urlWithUtmTags().search)
    return { ...anonymized, ...queryParams, referer: window.location.href }
  }

  (function(send) {
    XMLHttpRequest.prototype.send = async function(body) {
      // pass request if it has no body
      // or this is request after submiting form (if formIsSubmited === true)
      if (body === null || body === undefined || formIsSubmited === true) {
        return send.call(this, body)
      }

      try {
        let data = {}
        if (isJson(body)) {
          data = typeof body === 'string' ? JSON.parse(body) : body
        } else {
          let values = []
          body.split('&').forEach(function(value) {
            values = value.split('=')
            data[values[0]] = decodeURIComponent(values[1]).replace(/\+/g, ' ')
          })
        }

        data = handleNinjaFormsSubmission(data)
        data = clearSensitiveJsonData(data)
        const leadPayload = findLeadContacts(data)
        if (payloadHasUserParams(leadPayload)) {
          const merged = { ...data, ...leadPayload }
          const payload = preparePayload(merged)
          await invokeSubmitLead(payload)
        }
      } catch (e) {
        console.log(body) // eslint-disable-line
        console.error(e) // eslint-disable-line
      }

      send.call(this, body)
    }
  })(XMLHttpRequest.prototype.send)

  function handleNinjaFormsSubmission(data) {
    if (data['action'] !== 'nf_ajax_submit') return data

    const formData = JSON.parse(data['formData'])

    copyKeyValPairs(formData['fields'], data)

    return data
  }

  function copyKeyValPairs(from, to) {
    Object.entries(from).forEach(entry => {
      const [key, value] = entry

      to[key] = value['value']
    })
  }

  function anonymize(data) {
    if (document.getElementById('m2-leads-script')?.dataset.anonymize === undefined) {
      return data
    }

    return {
      fullName: `Name #${Date.now()}`,
      phoneNumber: '00000000',
      email: `${uuid().split('-').pop()}@anonymous.eml`
    }
  }

  function toArray(list) {
    const array = []
    const listLength = list.length

    for(let i = 0; i < listLength; i++) {
      array.push(list[i])
    }

    return array
  }

  function namesSelector(name) {
    const allFieldNames = [name, capitalize(name), name.toUpperCase()]
    return allFieldNames.map((name) => `[name*="${name}"]`).join(',')
  }

  function findValueInForm(form, matcher) {
    let trimmedValue = null
    return toArray(form.elements).reduce(function (result, element) {
      if (result) {
        return result
      }

      trimmedValue = element.value?.trim()
      if (trimmedValue && !avoidFields.includes(element.name) && trimmedValue.match(matcher)) {
        return trimmedValue
      }
    }, null)
  }

  const fieldsFindersForForm = {
    email(form) {
      let emailInput = null
      let emailInputValue = null
      let emailInputType = null

      emailValues.some((values) => {
        emailInput = form.querySelector(namesSelector(values))
        emailInputType = emailInput?.type || 'email'
        if (emailInput && emailInput.value && emailInputTypes.includes(emailInputType)) {
          emailInputValue = emailInput.value
          return true
        }
      })

      if (emailInputValue !== null ) return emailInputValue
      return findValueInForm(form, EMAIL_REGEXP)
    },
    phoneNumber(form) {
      let phoneInput = null
      let phoneInputValue = null

      phoneValues.some((values) => {
        phoneInput = form.querySelector(namesSelector(values))
        if (phoneInput && phoneInput.value) {
          phoneInputValue = phoneInput.value
          return true
        }
      })

      if (phoneInputValue !== null ) return phoneInputValue
      return findValueInForm(form, PHONE_REGEXP)
    },
    fullName(form) {
      let nameInput = null
      let nameInputValue = null

      fullNameValues.some((values) => {
        nameInput = form.querySelector(namesSelector(values))
        if (nameInput && nameInput.value) {
          nameInputValue = nameInput.value
          return true
        }
      })

      if (nameInputValue !== null ) return nameInputValue

      const nameFromParts = findNameFromParts((value) => {
        return form.querySelector(namesSelector(value))
      })
      if (nameFromParts !== null) return nameFromParts

      genericNameValues.some((values) => {
        nameInput = form.querySelector(namesSelector(values))
        if (nameInput && nameInput.value) {
          nameInputValue = nameInput.value
          return true
        }
      })
      if (nameInputValue !== null) return nameInputValue

      return findValueInForm(form, NAME_REGEXP)
    }
  }

  function findLeadContactsFromForm(form) {
    return Object.keys(fieldsFindersForForm).reduce(function (fieldsValues, finderKey) {
      const value = fieldsFindersForForm[finderKey](form)

      if (value) {
        fieldsValues[finderKey] = value
      }

      return fieldsValues
    }, {})
  }

  function elementIsSensitive(name) {
    return sensitiveValues.includes(name)
  }

  function formValues(form) {
    return toArray(form.elements).reduce(function(values, element) {
      if (elementIsSensitive(element.name)) return values
      values[element.name] = modifyValue(element.value)
      return values
    }, {})
  }

  function modifyValue(value) {
    if (value === 'true') return true
    if (value === 'false') return false
    return value
  }

  function submitFormLead(form) {
    if (!form.checkValidity()) return

    const leadPayload = findLeadContactsFromForm(form)
    if (payloadHasUserParams(leadPayload)) {
      // eslint-disable-next-line max-len
      const payload = preparePayload({ ...formValues(form), ...leadPayload })
      const requestStartTimestamp = Date.now()
      formIsSubmited = true
      invokeSubmitLead(payload)

      // freeze thread for some time to prevent form submission
      // end wait for request to be sent
      // otherwise page can be reloaded before request is sent
      // and lead will not reach Marketer
      const freezeTime = 1000
      const iterations = 1000000000
      const timeCheckAmount = 10
      for (let i = 0; i < iterations; i++) {
        const needTimeCheck = !(i % (iterations/timeCheckAmount))

        if (needTimeCheck && Date.now() - requestStartTimestamp > freezeTime) {
          break
        }
      }
    }
  }

  toArray(document.getElementsByTagName('form')).forEach(function (form) {
    // intercept form submit events
    form.parentNode.addEventListener('submit', (_e) => {
      return submitFormLead(form)
    }, true)

    // intercept form click events
    form.addEventListener('click', (e) => {
      const targetIsInput = (e.target.tagName === 'BUTTON' || e.target.tagName === 'INPUT')
      if (targetIsInput && e.target.getAttribute('type') === 'submit') {
        return submitFormLead(form)
      }
      if (e.target.closest('button[type="submit"]') || e.target.closest('input[type="submit"]')) {
        return submitFormLead(form)
      }
    }, true)
  })

  window.addEventListener('message', (e) => {
    if (credentials.acceptedMessageOrigins.includes(e.origin)) {
      const parsedMessage = JSON.parse(e.data)
      if ('marketerPayload' in parsedMessage) {
        const payload = preparePayload(parsedMessage.marketerPayload)
        invokeSubmitLead(payload)
      }
    }
  })
})()
