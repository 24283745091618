/* eslint-disable max-len */
export default {
  mapboxApiKey: 'pk.eyJ1IjoibWFya2V0ZXItdGVjaG5vbG9naWVzIiwiYSI6ImNqendoMjVoejBocmUzb3E5aDBxaXNiNGQifQ.BrDmzfF2spPEsIiOAz-UkA',
  tinyMceEditorApiKey: '6vv3pzujy4q5oaolufr6i20j9s7d1poq7cus98ryf0v1me57',
  leadsEndpoint: 'https://f140wyh2dc.execute-api.eu-north-1.amazonaws.com/prod/leads',
  tagsEndpoint: 'https://m2.dev/floodlight_tags.json',
  tagsPack: 'https://m2.dev/packs/tags.js',
  portalHost: 'https://marketer-portal-production.herokuapp.com',
  acceptedMessageOrigins: ['https://vy-forms.herokuapp.com'],
  sentryDsn: 'https://2a5b916c7fc44da0ad25f285cb4fd24e@sentry.production.meglermatch.no/17'
}
