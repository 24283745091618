/* eslint-disable max-len */
export default {
  mapboxApiKey: 'pk.eyJ1IjoibWFya2V0ZXItdGVjaG5vbG9naWVzIiwiYSI6ImNqendoMjVoejBocmUzb3E5aDBxaXNiNGQifQ.BrDmzfF2spPEsIiOAz-UkA',
  tinyMceEditorApiKey: 'who9qngavs7k32ny8hp2176lj44crb99w4pd1r4wdqzemwoo',
  leadsEndpoint: 'http://localhost:3000/leads',
  tagsEndpoint: 'http://localhost:3000/floodlight_tags.json',
  tagsPack: 'http://localhost:3000/packs/tags.js',
  portalHost: 'http://localhost:5001',
  acceptedMessageOrigins: []
}
